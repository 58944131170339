
  
  @media (max-width: 768px) {
    .features .features-item+.features-item {
      margin-top: 40px;
    }
  }
  
  .features .features-item h3 {
    font-weight: 700;
    font-size: 26px;
  }

  .features .features-item ul {
    list-style: none;
    padding: 0;
  }
  
  .features .features-item ul li {
    padding-bottom: 10px;
    display: flex;
    align-items: flex-start;
  }
  
  .features .features-item ul li:last-child {
    padding-bottom: 0;
  }
  
  .features .features-item ul i {
    font-size: 20px;
    padding-right: 4px;
    color: var(--primary-color);
  }
  
  .features .features-item img {
    border: 6px solid var(--background-color);
    box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.315);
  }
  
  .features .features-item .features-img-bg {
    position: relative;
    min-height: 500px;
  }
  
  @media (max-width: 640px) {
    .features .features-item .features-img-bg {
      min-height: 300px;
    }
  }
  
  .features .features-item .features-img-bg img {
    position: absolute;
    inset: 0;
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }