  .advertisers-service-sec {
    background-color: #f5f5f5;
  }

  .advertisers-service-sec span {
    color:#017129;
  }

  .advertisers-service-sec .col {
    padding: 0 1em 1em 1em;
    text-align: center;
  }

  .advertisers-service-sec .service-card {
    width: 100%;
    height: 25rem;
    margin-bottom: auto;
    margin-top: auto;
    padding: 4em 1.5em;
    text-align: justify;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transition: 0.5s;
    position: relative;
    z-index: 0;
    overflow: hidden;
    background: #fff;
  }

  .advertisers-service-sec .service-card::after {
    content: "";
    width: 100%;
    height: 100%;
    background: #017129;
    position: absolute;
    left: 0%;
    top: -98%;
    z-index: 0;
    transition: all 0.4s cubic-bezier(0.77, -0.04, 0, 0.99);
  }

  .advertisers-service-sec h3 {
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 600;
    color: #017129;
    margin: 1em 0;
    z-index: 3;
  }

  .advertisers-service-sec p {
    color: #000;
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: 0.03em;
    z-index: 3;
  }


  .service-card .icon-wrapper {
    background-color: #ffffff;
    color: #017129;
  }
  .valImg {
    height: 25rem;
    width: 100%;
  }
  .service-btn {
    /* margin-left: 30px; */
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 25px;
    color: white;
    background-color: #017129;
    font-size: smaller;
    text-decoration: none;
  }
  .service-btn:hover {
    color: #fff;
  }
.headings {
  color: #017129;
  font-weight: bold;
  font-size: 24px;
}