@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");

:root {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}
/*--------------------------------------------------------------
# Sections
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-header {
  text-align: center;
  padding-bottom: 40px;
}

.section-header h2 {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  margin: 0;
  color: #017129;
  text-transform: uppercase;
}

.section-header p {
  margin: 10px 0 0 0;
  padding: 0;
  font-size: 38px;
  line-height: 42px;
  font-weight: 700;
  color: #017129;
}

@media (max-width: 768px) {
  .section-header p {
    font-size: 28px;
    line-height: 32px;
  }
}

/* HERO */

.hero {
  width: 100%;
  height: 75vh;
  background: #d3eee063;
  background-size: cover;
}
.hero h1 {
  margin: 0;
  font-size: 40px;
  font-weight: 700;
  color: #017129;
}

.hero h2 {
  color: #017129;
  margin: 15px 0 0 0;
  font-size: 26px;
}

.hero .btn-get-started {
  margin-top: 30px;
  line-height: 0;
  padding: 15px 40px;
  border-radius: 25px;
  transition: 0.5s;
  color: #fff;
  background: #017129;
  box-shadow: 0px 5px 30px rgba(65, 84, 241, 0.4);
  text-decoration: none;
}

.hero .btn-get-started span {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
}

.hero .btn-get-started i {
  margin-left: 5px;
  font-size: 18px;
  transition: 0.3s;
}

.hero .btn-get-started:hover i {
  transform: translateX(5px);
}

.hero .hero-img {
  text-align: right;
}

@media (min-width: 1024px) {
  .hero {
    background-attachment: fixed;
  }
}

@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 120px 0 60px 0;
  }

  .hero .hero-img {
    text-align: center;
    margin-top: 80px;
  }

  .hero .hero-img img {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .hero {
    text-align: center;
  }

  .hero h1 {
    font-size: 32px;
  }

  .hero h2 {
    font-size: 24px;
  }

  .hero .hero-img img {
    width: 100%;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes change {
  from {
    color: green;
  }
  to {
    color: red;
  }
}
.animation-hero-sec {
  animation: fadeIn 3.5s;
}
.animation-hero-sec h1 {
  animation: change 2s step-end both;
}
