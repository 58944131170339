.lead {
    font-size: 1rem;
    line-height: 30px;
    font-family: 'Poppins';
    text-align: justify;
}
.headings{
    font-family: 'Poppins',sans-serif;
    font-weight: bold;
    color: #017129;
}
.contentFont{
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 15px;
    text-align: justify;
    font-size: large;
    color: #000;
    width: 100%;
}
.column {
    margin: auto;
    width: 50%;
}
.column-header {
    margin: auto;
    background-color: #d3eee063;
    width: 50%;
}
.column-full {
    margin: auto;
    background-color: #d3eee063;

}

.btn-switch {
    color: #017129;
    background-color: white;
    border: none;
}

.btn-switch > a {
    text-decoration: none;
    color: #017129;
}

.nav-link.active {
    background-color: #017129 !important;
    font-weight: bold;
    color:white;
}
.nav-link {
    color: #017129;
}
.nav-link:hover{
    color: #017129;
}

.list-group-item {
    background-color: #d3eee063;
}