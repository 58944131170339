.header {
    position: relative;
}
img {
    object-fit: cover;
}
.heading {
    position: absolute;
    top: 125px;
    left: 50px;
    font-size: 42px;
    color: #017129;
    font-family: sans-serif;
}