.card {
    background-color: #d3eee063;
}
.card-title {
    color: #1B3C2B;
    font-family: 'Open Sans';
    font-weight: bold;
}

.card-text {
    font-family: 'Poppins';
    text-align: justify;
}
.headings{
    font-family: 'Poppins',sans-serif;
    font-weight: bold;
    color: #017129;
}
.contentFont{
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: justify;
    font-size: 15px;
    color: #000;
    width: 100%;
}

a:hover {
    color: white;
}