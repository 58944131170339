.lead {
    font-size: 1rem;
    line-height: 30px;
    font-family: 'Poppins';
    text-align: justify;
}

.headings{
    font-family: 'Poppins',sans-serif;
    font-weight: bold;
    color: #017129;
}
.lead-box {
    font-size: .8rem;
    line-height: 30px;
    font-family: 'Poppins';
    text-align: center;
}
.head-color {
    color: #017129;
}
.column {
    margin: auto;
    width: 50%;
}

.column-full {
    margin: auto;
    background-color: #d3eee063;
}

.head {
    font-family:'Open Sans';
    font-weight: bold;
}

.btn-switch {
    color: #017129;
    background-color: white;
    border: none;
}

.btn-switch > a {
    text-decoration: none;
    color: #017129;
}

.btn-switch > a:hover {
    background-color: #017129;
    color: white;
}

.nav-link.active {
    background-color: #017129 !important;
    font-weight: bold;
}

.list-group-item {
    background-color: #d3eee063;
}