@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&family=Poppins:wght@300&display=swap");
.main_spc_bank {
  padding: 10px;
  font-family: "Poppins", sans-serif;
}

.main_spc_bank h4 {
  font-family: "Open Sans", sans-serif;
}
.main_spc_bank h2 {
  font-family: "Open Sans", sans-serif;
  color: #ff0000;
}

.main_spc_bank_green {
  color: #017129;
}
.main_spc_bank_red {
  color: #ff0000;
}
.spc-bank-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.icon-box i {
  font-size: 48px;
  color: #017129;
}

.image_number_style {
  position: relative;
  top: 30px;
  left: 25px;
  border: 1px solid white;
  background: green;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  text-align: center;
  color: white;
  margin-top: 5px;
}
.image_number_style_center {
  position: relative;
  top: 30px;
  left: 40px;
  border: 1px solid white;
  background: green;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  text-align: center;
  color: white;
  margin-top: 5px;
}
.number_style {
  border: 1px solid white;
  background: red;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  text-align: center;
  color: white;
}
