.btn {
    text-decoration: none;

    border-radius: 30px;
    background-color: #017129;
    color: white;
    border: none;
}

.btn:hover {
    background-color: #017129;
}