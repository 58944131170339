.pricing .box {
    padding: 40px 20px;
    background: #fff;
    text-align: center;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    transition: 0.3s;
    z-index: 0;
  }

  .pricing .box:hover {
    /* transform: scale(1.1); */
    box-shadow: 5px 5px 30px rgba(1, 41, 112, 0.1);
  }

  .pricing h3 {
    font-weight: 700;
    font-size: 18px;
    text-transform: capitalize;
    margin-bottom: 15px;
  }

  .pricing .price {
    font-size: 36px;
    color: #444444;
    font-weight: 600;
  }

  .pricing .price sup {
    font-size: 20px;
    top: -15px;
    left: -3px;
  }

  .pricing .price span {
    color: #bababa;
    font-size: 16px;
    font-weight: 300;
  }

  .pricing img {
    padding: 30px 40px;
  }

  .pricing ul {
    padding: 0;
    list-style: none;
    color: #444444;
    text-align: center;
    line-height: 26px;
    font-size: 16px;
    margin-bottom: 25px;
  }

  .pricing ul li {
    padding-bottom: 10px;
  }

  .pricing ul .na {
    color: #ccc;
    text-decoration: line-through;
  }

  .pricing .btn-buy {
    display: inline-block;
    padding: 8px 40px 10px 40px;
    border-radius: 50px;
    color: #017129;
    transition: none;
    font-size: 16px;
    font-weight: 400;
    font-weight: 600;
    transition: 0.3s;
    border: 1px solid #017129;
    text-decoration: none;
  }

  .pricing .btn-buy:hover {
    background: #017129;
    color: #fff;
  }
