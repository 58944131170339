.about2 {
    background: url("../../assets/about-bg.jpg") center center no-repeat;
    background-size: cover;
    padding: 90px;
    position: relative;
    z-index: 0;
  }

  .about2::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.75);
    z-index: 0;
  }

  .about2 .container2 {
    position: relative;
    z-index: 0;
  }

  .about2 .content2 {
    /* padding: 30px 30px 30px 0; */
    /* padding: 30px; */
  }

  .about2 .content2 h3 {
    font-weight: 700;
    font-size: 34px;
    color: #017129;
    /* margin-bottom: 30px; */
  }

  .about2 .content2 p {
    margin-bottom: 30px;
  }

  .about2 .content2 .about-btn2 {
    display: inline-block;
    background: #017129;
    padding: 6px 44px 8px 30px;
    color: #fff;
    border-radius: 50px;
    transition: 0.3s;
    position: relative;
    text-decoration: none;
  }

  .about2 .content2 .about-btn2 i {
    font-size: 18px;
    position: absolute;
    right: 18px;
    top: 9px;
  }

  .about2 .content2 .about-btn2:hover {
    background: #017129;
  }

  .about2 .icon-boxes .icon-box {
    margin-top: 30px;
  }

  .about2 .icon-boxes .icon-box i {
    font-size: 40px;
    color: #017129;
    margin-bottom: 10px;
  }

  .about2 .icon-boxes .icon-box h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 10px 0;
  }

  .about2 .icon-boxes .icon-box p {
    font-size: 15px;
    color: #017129;
  }

  @media (max-width: 1200px) {
    .about2 .content2 {
      padding-right: 0;
    }
  }

  @media (max-width: 768px) {
    .about2 {
      text-align: center;
    }
  }
