.lead {
    font-size: 1rem;
    line-height: 30px;
    font-family: 'Poppins';
    text-align: justify;
}
.lead-box {
    font-size: .8rem;
    line-height: 30px;
    font-family: 'Poppins';
    text-align: center;
}
.head-color {
    color: #017129;
}
.column {
    margin: auto;
    width: 50%;
}

.column-full {
    margin: auto;
    background-color: #d3eee063;
}

.head {
    font-family:'Open Sans';
}

.btn-switch {
    background-color: #017129;
    color: black;
    font-family: 'Poppins';
    border: 2px solid #017129;
}

.link-team {
    text-decoration: none;
    color: #017129;
}

.link-team:hover {
    color: #017129;
}
.cardImage {
    height: 250px;
    object-fit: "cover";
     width: "100%"
}

.appointment-img{
    width: 50%;
}

@media only screen and (max-width: 600px) {
    .cardImage {
        height: 400px;
    }
    .appointment-img{
        width: 80%;
    }
}